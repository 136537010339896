import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { ApolloProvider } from '@apollo/react-hooks';
import { theme } from './theme';
import Routes from './routes';
import ApolloClient from 'apollo-boost';
import * as serviceWorker from './serviceWorker';
import './theme/global.css';

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  fetchOptions: {
    credentials: 'include'
  },
  request: async (operation) => {
    console.log('===========================');
    console.log(operation.getContext());
    const ctx = operation.getContext();
    const headers = ctx ? ctx.headers : null;
    let email = '';
    let id = '';
    let isAdministrator = 0;

    if (headers && headers.fromUser) {
      email = headers.fromUser.email || '';
      id = headers.fromUser.id || '';
      isAdministrator = headers.fromUser.isAdministrator ? 1 : 0;
    }

    operation.setContext({
      headers: {
        authorization: email + ' ' + id + ' ' + isAdministrator,
      }
    });
  },
  headers: { token: 'VDRFUXZjVFVEcFR3NEk1dzBHeUlxQTNnR3VVMlhTaG9wQURAMjAyMDUyNA==' },
});

function App() {
  const engine = new Styletron();

  return (
    <ApolloProvider client={client as any}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </BaseProvider>
      </StyletronProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
