import React from 'react';
import firebase, { auth } from '../firebase';
import { GET_ACTIVE_TEACHER_BY_MAIL } from '../graphQL/query/teacher.queries';
import { useQuery, useApolloClient } from '@apollo/react-hooks';

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signout: Function;
  onLoginStatusChange: Function;
  currentUser: any;
  changePassword: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(false);

  const initCurrentUser = () => {
    return {
      email: '',
      isAdministrator: false
    };
  }

  const [currentUser, setCurrentUser] = React.useState(initCurrentUser());
  const client = useApolloClient();

  const { data, error, refetch } = useQuery(GET_ACTIVE_TEACHER_BY_MAIL, { skip: true });

  const checkAdminUser = (user, cb = null) => {
    refetch({
      email: user.email
    })
      .then(({ data }: any = {}) => {
        console.log((data || {}).getActiveTeacherByEmail, 'refetch teacher info');
        if (!data || !data.getActiveTeacherByEmail) {
          // localStorage.removeItem("user");
          cb && cb("You don't have permission to access this page!");
          return;
        }

        const activeTeacher = data.getActiveTeacherByEmail;
        setCurrentUser(activeTeacher);
        // localStorage.setItem("user", JSON.stringify(activeTeacher));
        makeAuthenticated(true);
        cb && cb();
      })
      .catch(e => {
        cb && cb("User doesn't exsit!");
        console.log(e);
      })
  }

  const handleLogin = (user, cb) => {
    checkAdminUser(user, cb);
  }

  const performLogin = (email, password, cb) => {
    auth.signInWithEmailAndPassword(email, password)
      .then(({ user }) => {
        handleLogin(user, cb);
      })
      .catch((error) => {
        console.log(error.message);
        cb(error.message);
        alert(error.message);
      });
  }

  function onLoginStatusChange() {
    firebase.auth().onAuthStateChanged((user: any) => {
      if (!currentUser || currentUser.isAdministrator || user && currentUser.email != user.email) {
        return;
      }

      if (!user) {
        // localStorage.removeItem("user");
        signout(null);
        return;
      }

      checkAdminUser(user);
    });
  }

  function authenticate({ username, password }, cb) {
    performLogin(username, password, cb);
  }

  function signout(cb) {
    auth.signOut();
    makeAuthenticated(false);
    setCurrentUser(initCurrentUser());
    client.resetStore();
    // localStorage.removeItem('user');

    if (cb) {
      setTimeout(cb, 100);
    }
  }

  function changePassword(email) {
    auth.sendPasswordResetEmail(email).then(function (result) {
      alert(`Chúng tôi đã gửi 1 Đổi Mật Khẩu email đến ${email}. Hãy kiểm tra email của bạn!`)
    }).catch(e => {
      console.log(e);
      alert(e.message);
    })
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
        onLoginStatusChange,
        currentUser,
        changePassword
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
