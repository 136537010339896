import React, { useContext, lazy, Suspense, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  LOGIN,
  PRODUCTS,
  EXAMINATION,
  DASHBOARD,
  CONSTRUCTION,
  NOTIFICATION,
  ASSET,
  CUSTOMERS,
  TEACHERS,
  PEOPLE
} from "./settings/constants";
import { drawer } from './constant/index';
import AuthProvider, { AuthContext } from "./context/auth";
import { InLineLoader } from "./components/InlineLoader/InlineLoader";
const Products = lazy(() => import("./containers/Products/Products"));
const AdminLayout = lazy(() => import("./containers/Layout/Layout"));
const Dashboard = lazy(() => import("./containers/Dashboard"));
const NotificationManagement = lazy(() => import("./containers/NotificationManagement"));
const PeopleManagement = lazy(() => import("./containers/PeopleManagement"));
const ConstructionManagement = lazy(() => import("./containers/ConstructionManagement"));
const Examination = lazy(() => import("./containers/Examination/Examination"));
const Assets = lazy(() => import("./containers/Asset/Asset"));
const SiteSettingForm = lazy(() =>
  import("./containers/SiteSettingForm/SiteSettingForm")
);
const StaffMembers = lazy(() =>
  import("./containers/StaffMembers/StaffMembers")
);
const Customers = lazy(() => import("./containers/Customers/Customers"));
const Teachers = lazy(() => import("./containers/Teacher/Teachers"));
const Login = lazy(() => import("./containers/Login/Login"));
const NotFound = lazy(() => import("./containers/NotFound/NotFound"));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated, onLoginStatusChange, currentUser } = useContext(AuthContext);
  const { isAdminOnly } = rest;

  useEffect(() => {
    onLoginStatusChange();
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated || true ? (
          !isAdminOnly ? children : currentUser && currentUser.isAdministrator ?
            children : <div>Not found!</div>
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
         <PrivateRoute exact path={DASHBOARD}>
            <AdminLayout drawerType={drawer.PRODUCT_FORM}>
              <Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
           <PrivateRoute path={CONSTRUCTION}>
            <AdminLayout drawerType={drawer.CONSTRUCTION_FORM}>
              <Suspense fallback={<InLineLoader />}>
                <ConstructionManagement />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={NOTIFICATION}>
            <AdminLayout drawerType={drawer.CONSTRUCTION_FORM}>
              <Suspense fallback={<InLineLoader />}>
                <NotificationManagement />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PEOPLE}>
            <AdminLayout drawerType={drawer.CONSTRUCTION_FORM}>
              <Suspense fallback={<InLineLoader />}>
                <PeopleManagement />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTS}>
            <AdminLayout drawerType={drawer.PRODUCT_FORM}>
              <Suspense fallback={<InLineLoader />}>
                <Products />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={EXAMINATION}>
            <AdminLayout drawerType={drawer.EXAMIMATION_FORM}>
              <Suspense fallback={<InLineLoader />}>
                <Examination />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ASSET}>
            <AdminLayout drawerType={drawer.ASSET_FORM}>
              <Suspense fallback={<InLineLoader />}>
                <Assets />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CUSTOMERS} isAdminOnly={true}>
            <AdminLayout drawerType={drawer.PRODUCT_FORM}>
              <Suspense fallback={<InLineLoader />}>
                <Customers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={TEACHERS} isAdminOnly={true}>
            <AdminLayout drawerType={drawer.TEACHER_FORM}>
              <Suspense fallback={<InLineLoader />}>
                <Teachers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
