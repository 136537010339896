import gql from 'graphql-tag';

export const GET_TEACHER = gql`
  query GET_TEACHER {
    teachers {
      id
      name
      email
      isAdministrator
      isApproved
      firebaseUID
      creation_date
    }
  }
`;

export const GET_ACTIVE_TEACHER_BY_MAIL = gql`
  query getActiveTeacherByEmail($email: String) {
    getActiveTeacherByEmail(email: $email) {
      id
      name
      email
      isAdministrator
      isApproved
      firebaseUID
      creation_date
    }
  }
`;

export const GET_ACTIVE_TEACHERS = gql`
  query GET_ACTIVE_TEACHERS($searchByName: String) {
    activeTeachers(searchByName: $searchByName) {
      id
      name
      email
      isAdministrator
      isApproved
      firebaseUID
      creation_date
    }
  }
`;
