// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const CONSTRUCTION= '/cong-truong';
export const NOTIFICATION = '/thong-bao';
export const PEOPLE = '/quan-ly-nhan-vien';
export const PRODUCTS = '/bai-giang';
export const EXAMINATION = '/bai-kiem-tra';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const ASSET = '/tu-lieu';
export const CUSTOMERS = '/customers';
export const TEACHERS = '/teachers';
export const SETTINGS = '/settings';
export const STUFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
// **************** ROUTE CONSTANT END **************************
