import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyC99hsWaxynCFgjOhmLtRuNjpL6gCHzy7Y",
    authDomain: "ql-cong-trinh.firebaseapp.com",
    databaseURL: "https://ql-cong-trinh.firebaseio.com",
    projectId: "ql-cong-trinh",
    storageBucket: "ql-cong-trinh.appspot.com",
    messagingSenderId: "61189680105",
    appId: "1:61189680105:web:743ecb6a517dde03dfb978"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const storage = firebase.storage();
const auth = firebase.auth();
const firebaseDatabase = firebase.database();
const firestore = firebase.firestore();

export {
    storage, auth, firebaseDatabase, firestore, firebase as default
}
