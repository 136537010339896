export const drawer = {
  PRODUCT_FORM: { name: 'PRODUCT_FORM', displayName: 'Thêm Bài Giảng' },
  CONSTRUCTION_FORM: {
    name: 'CONSTRUCTION_FORM',
    displayName: 'Thêm Công Trình',
  },
  PEOPLE_FORM: {
    name: 'PEOPLE_FORM',
    displayName: 'Thêm Nhân Viên',
  },
  PRODUCT_UPDATE_FORM: {
    name: 'PRODUCT_UPDATE_FORM',
    displayName: 'Tùy Chỉnh Bài Giảng',
  },
  CAMPAING_FORM: { name: 'CAMPAING_FORM', displayName: 'Update CAMPAING' },
  EXAMIMATION_UPDATE_FORM: {
    name: 'EXAMIMATION_UPDATE_FORM',
    displayName: 'Tùy Chỉnh bài tập',
  },
  EXAMIMATION_FORM: { name: 'EXAMIMATION_FORM', displayName: 'Thêm bài tập' },
  STAFF_MEMBER_FORM: { name: 'STAFF_MEMBER_FORM', displayName: 'Add Staffs' },
  ASSET_FORM: { name: 'ASSET_FORM', displayName: 'Thêm tư liệu' },
  ASSET_UPDATE_FORM: {
    name: 'ASSET_UPDATE_FORM',
    displayName: 'Tùy Chỉnh Tư Liệu',
  },
  TEACHER_FORM: { name: 'TEACHER_FORM', displayName: 'Thêm Giáo Viên' },
};

export const userRole = {
  MANAGER: 1,
  WORKER: 0,
};

export const roleMapping = ['Công Nhân', 'Quản Lý'];

export const markerColor = {
  MANAGER: '00c58d',
  WORKER: 'fc5c63',
  UNKNOW: 'FFFFFF',
};

export const peopleAttribute = {
  ASSIGNED_CONSTRUCTION: 'assignedConstruction',
  NAME: 'name',
  ROLE: 'role',
  SUPPORT_RADIUS: 'supportRadius',
  TRACKED_LOCATION: 'trackedLocation',
};

export const timeAttribute = {
  CREATION_TIME: 'creationTime',
  MODIFICATION_DAY: 'modificationTime',
};

export const drawerAction = {
  OPEN_DRAWER: 'OPEN_DRAWER',
  CLOSE_DRAWER: 'CLOSE_DRAWER',
};

export const requestType = {
  HELP: 'HELP',
  SUPPORT: 'SUPPORT',
  IN: 'IN',
  OUT: 'OUT',
};

export const requestAttribute = {
  CLOSE_REQUEST_TIME: 'closeRequestTime',
  TYPE: 'type',
  OPEN_REQUEST_TIME: 'openRequestTime',
  CONSTRUCTION: 'construction',
};

export const severityColor = {
  HELP: 'backgroundLightNegative',
  OUT: 'backgroundLightWarning',
  IN: 'contentInversePrimary',
  SUPPORT: 'backgroundPositive',
};

export const collectionName = {
  PEOPLE: 'people',
  CONSTRUCTION: 'construction',
};

// export const mapSetting = {
//   VIEW_ONLY_MAP: 'viewMode',
//   EDITABLE_MAP: 'editableMode',
// };
